import { HeaderContainer, HeaderTop } from "./style";

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderTop>
        <img src="/assets/images/png/logo.png" alt="" />
      </HeaderTop>
    </HeaderContainer>
  );
};

export default Header;
