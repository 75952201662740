import React from 'react'
import { useAppDispatch } from '../Redux/app/hooks';
import { getApplicationDetails } from '../Redux/features/ApplicationFormSlice';
import AuthPageLayout from '../Components/Layouts/AuthPageLayout';
import { FormContainer, ItemsCenterJustifyBtw } from './styles';
import CustomRegularDropdown from '../Components/Form/Selectors/RegularDropdown';
import BigButton from '../Components/Form/Buttons/BigButton';

const ProgramDetails = () => {
   const dispatch = useAppDispatch();

   const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
     e.preventDefault();
     const { name, value } = e.target;
     dispatch(getApplicationDetails({ name, value }));
   };
   return (
     <AuthPageLayout authText="Program Details">
       <FormContainer>
         <ItemsCenterJustifyBtw className="flex justify-between">
           <CustomRegularDropdown
             title="Admission Type"
             name="admissionType"
             handleDropdownSelect={handleOnChange}
             dropdownItems={[{ label: "JAMB UTME", value: "JAMB UTME" }]}
           />
           <CustomRegularDropdown
             title="Faculty"
             name="admissionType"
             handleDropdownSelect={handleOnChange}
             dropdownItems={[{ label: "JAMB UTME", value: "JAMB UTME" }]}
           />
         </ItemsCenterJustifyBtw>
         <BigButton
           buttonText="Proceed"
           disabled={false}
           // handleClick={handleProceed}
           // loading={sendingApplicationForm}
         />
       </FormContainer>
     </AuthPageLayout>
   );
}

export default ProgramDetails