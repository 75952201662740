import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import {
  clearData,
  setConfirmingPayment,
  setSendingApplicationForm,
  setStudentsApplicationDetails,
} from "../Redux/features/ApplicationFormSlice";
import Swal, { SweetAlertOptions } from "sweetalert2";

export const sendApplicationForm = async (
  firstName: string,
  lastName: string,
  otherName: string,
  contactAddress: string,
  emailAddress: string,
  mobilePhone: string,
  dateOfBirth: string,
  stateId: string,
  localGovernmentId: string,
  countryId: number
) => {
  store.dispatch(setSendingApplicationForm(true));
  const body = {
    firstName,
    lastName,
    otherName,
    contactAddress,
    emailAddress,
    mobilePhone,
    dateOfBirth,
    stateId,
    localGovernmentId,
    countryId,
  };
  await api
    .post("Admission/NewStudent", body)
    .then((res) => {
      const { data } = res;
      localStorage.setItem("newStudentDetails", JSON.stringify(data?.data));
      Swal.fire({
        title: "Application initiated successfully",
        text: "A link to complete your application has been sent to your email",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      } as SweetAlertOptions).then(() => {
        store.dispatch(clearData());
      });
      store.dispatch(setSendingApplicationForm(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingApplicationForm(false));
    });
};

export const completeUndergraduateStudent = async (
  firstname?: string | null,
  lastname?: string | null,
  othername?: string | null,
  fullname?: string | null,
  dateOfBirth?: string | null,
  disabilityId?: number | null,
  emailAddress?: string | null,
  genderId?: number | null,
  contactAddress?: string | null,
  mobilePhone?: string | null,
  stateId?: string | null,
  localGovernmentId?: string | null,
  homeAddress?: string | null,
  countryId?: number | null,
  religionId?: number | null,
  admissionTypeId?: number | null,
  parentName?: string | null,
  parentNumber?: string | null,
  nextOfKin?: object | null,
  jambRecord?: {
    regNumber: string;
    score: number;
    year: number;
    firstSubjectId: number;
    firstSubjectScore: number;
    secondSubjectId: number;
    seconeSubjectScore: number;
    thirdSubjectId: number;
    thirdSubjectScore: number;
    forthSubjectId: number;
    forthSubjectScore: number;
  },
  olevelData?: {
    firstsubjectArray?: object[];
    secondSubjectArray?: object[];
    firstexamNumber: string;
    firstexamType: number;
    firstexamYear: number;
    secondexamNumber: string;
    secondexamType: number;
    secondexamYear: number;
  },
  hobbies?: string | null,
  previousEducation?: {
    institution: string;
    certificate_obtained: string;
    course_of_study: string;
    class_of_certificate: string;
    date_of_graduation: string;
    cgpa: string;
  },
  personId?: number | null,
  departmentId?: number | null,
  tribeId?: number | null,
  homeTown?: string | null,
  advertMeansId?: number | null,
  referral?: string | null
) => {
  store.dispatch(setSendingApplicationForm(true));
  const body: any = {
    firstname,
    lastname,
    othername,
    fullname,
    dateOfBirth,
    disabilityId,
    emailAddress,
    genderId,
    contactAddress,
    mobilePhone,
    stateId,
    localGovernmentId,
    homeAddress,
    countryId,
    religionId,
    admissionTypeId,
    parentName,
    parentNumber,
    nextOfKin,
    jambRecord,
    olevelData,
    personId,
    previousEducation: previousEducation?.institution
      ? previousEducation
      : null,
    hobbies,
    departmentId,
    tribeId,
    homeTown,
    advertMeansId,
    referral,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("/Admission/CompleteUgStudent", body)
    .then(() => {
      store.dispatch(setSendingApplicationForm(false));
      toast.success("student registered successfully");
      Swal.fire({
        title: "Application completed successfully",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      } as SweetAlertOptions).then(() => {
        window.location.href = "/";
        store.dispatch(clearData());
      });
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSendingApplicationForm(false));
    });
};

export const confirmPayment = async (reference: any) => {
  store.dispatch(setConfirmingPayment(true));
  const params = {
    reference,
  };
  await api
    .get("/Payments/ComfirmEtranzactCredoPayment", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsApplicationDetails(data?.data));
      localStorage.setItem("studentInfo", JSON.stringify(data?.data));
      store.dispatch(setConfirmingPayment(false));
      toast.success("Payment Confirmed");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingPayment(true));
    });
};

export const getStudentApplicationInfo = async (personId: any) => {
  store.dispatch(setConfirmingPayment(true));
  await api
    .get(`/Payments/GetStudentInfo/${personId}/continuo`)
    .then((res) => {
      const { data } = res;
      store.dispatch(setStudentsApplicationDetails(data?.data));
      store.dispatch(setConfirmingPayment(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingPayment(true));
    });
};
