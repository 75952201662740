import React from "react";
import { ItemsCenterJustifyBtw } from "./styles";
import CustomRegularDropdown from "../Components/Form/Selectors/RegularDropdown";
import { filter } from "./utils";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../Redux/features/ApplicationFormSlice";
import { selectUtil } from "../Redux/features/utilSlice";
import TextInputWithIcon from "../Components/Form/Inputs/TextInputWithIcon";
import { toast } from "react-toastify";

const AddJambResult = () => {
  const {
    firstSubjectId,
    firstSubjectScore,
    secondSubjectId,
    seconeSubjectScore,
    thirdSubjectId,
    thirdSubjectScore,
    forthSubjectId,
    forthSubjectScore,
  } = useAppSelector(selectapplicationForm);
  const { allSubjects } = useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const subjectFilter = filter(
    allSubjects,
    "olevelSubjectName",
    "olevelSubjectId"
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = maxNumberInput(value);
    dispatch(getApplicationDetails({ name, value: newValue }));
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const maxNumberInput = (value: string) => {
    if (parseInt(value) > 100 || parseInt(value) < 0) {
      toast.warning("Score should not be greater than 100 or less than 0");
      return 0;
    } else {
      return value;
    }
  };

  return (
    <>
      <ItemsCenterJustifyBtw className="flex justify-between">
        <CustomRegularDropdown
          title="Subject 1"
          handleDropdownSelect={handleSelect}
          value={firstSubjectId}
          dropdownItems={subjectFilter}
          name="firstSubjectId"
        />
        <TextInputWithIcon
          type="number"
          placeholder="Score"
          name="firstSubjectScore"
          handleChange={handleOnChange}
          value={firstSubjectScore}
          maxLength={4}
        />
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="flex justify-between">
        <CustomRegularDropdown
          title="Subject 2"
          handleDropdownSelect={handleSelect}
          value={secondSubjectId}
          dropdownItems={subjectFilter}
          name="secondSubjectId"
        />
        <TextInputWithIcon
          type="number"
          placeholder="Score"
          name="seconeSubjectScore"
          handleChange={handleOnChange}
          value={seconeSubjectScore}
          maxLength={4}
        />
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="flex justify-between">
        <CustomRegularDropdown
          title="Subject 3"
          handleDropdownSelect={handleSelect}
          value={thirdSubjectId}
          dropdownItems={subjectFilter}
          name="thirdSubjectId"
        />
        <TextInputWithIcon
          type="number"
          placeholder="Score"
          name="thirdSubjectScore"
          handleChange={handleOnChange}
          value={thirdSubjectScore}
          maxLength={4}
        />
      </ItemsCenterJustifyBtw>
      <ItemsCenterJustifyBtw className="flex justify-between">
        <CustomRegularDropdown
          title="Subject 4"
          handleDropdownSelect={handleSelect}
          value={forthSubjectId}
          dropdownItems={subjectFilter}
          name="forthSubjectId"
        />
        <TextInputWithIcon
          type="number"
          placeholder="Score"
          name="forthSubjectScore"
          handleChange={handleOnChange}
          value={forthSubjectScore}
          maxLength={4}
        />
      </ItemsCenterJustifyBtw>
    </>
  );
};

export default AddJambResult;
