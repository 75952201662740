import styled from "styled-components";
import { media } from "../../../Screens";

export const AuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-items: center; */
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image: url("/assets/images/PNG/auth-page-bg.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  ${media.tablet} {
    flex-direction: row;
  }
  ${media.tablet} {
    margin-top: 100px;
  }

  // &::before {
  //   width: 816px;
  //   height: 816px;
  //   flex-shrink: 0;
  //   content: "";
  //   border-radius: 816px;
  //   opacity: 1;
  //   // background: radial-gradient(67.22% 50% at 50% 50%, rgba(51, 231, 255, 0.15) 0%, rgba(245, 228, 255, 0) 100%);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 0;
  // }
  // &::after {
  //   width: 816px;
  //   height: 816px;
  //   flex-shrink: 0;
  //   content: "";
  //   border-radius: 816px;
  //   opacity: 0.9;
  //   // background: radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0) 100%);
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 0;
  // }
`;

export const LeftContent = styled.div`
  flex: 1;
  z-index: 2;

  ${media.tablet} {
    flex: 0.5;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 2.25rem;
  /* position: relative; */
  z-index: 99;
  overflow-y: auto;

  ${media.desktop} {
    width: 80%;
  }
  ${media.phone} {
    // padding-top: 200px;
  }
`;

export const AuthText = styled.p`
  color: var(--clr-primary-900);
  font-size: 30px;
  font-style: normal;
  font-weight: var(--fw-very-bold);
  line-height: normal;
  text-transform: uppercase;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  gap: 1.25rem;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 1px 5px 5px 5px  #e2e2e2;

  ${media.tablet} {
    width: 70%;
  }

  span {
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
  }
`;

export const SubTitle = styled.p`
  color: var(--clr-primary-900);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--fw-very-bold);
  line-height: normal;
  text-transform: uppercase;
`;

export const CompanyLogo = styled.img`
  width: 100px;
`;

export const RightContent = styled.div`
  flex: 1;
  display: none;
  z-index: 1;

  ${media.tablet} {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.875rem 4.75rem;
    height: 100vh;
  }
`;

export const AuthBanner = styled.div`
  // background: var(--clr-primary-900);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding: 1.5rem 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 17px;
  border-radius: 15px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
  color: var(--clr-white-100);

  img {
    width: 100%;
    height: 100%;
    opacity: 0.35;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

export const TitleSection = styled.div`
  width: 100%;

  p:first-child {
    font-size: var(--size-700);
    font-weight: var(--fw-semi-bold);
    line-height: normal;
  }
`;

export const ContentSection = styled.div`
  height: auto;
`;
