import { toast } from "react-toastify";
import { store } from "../Redux/app/store";
import api from "./baseUri";
import {
  setAllAdmissionType,
  setAllLGA,
  setAllReligion,
  setAllStates,
  setAllSubjectGrade,
  setAllSubjects,
  setDepartments,
  setExamTypes,
  setExamYears,
  setGettingLGA,
  setGettingState,
  setTribes,
} from "../Redux/features/utilSlice";

export const getState = async () => {
  store.dispatch(setGettingState(true));
  await api
    .get("/Utill/GetState")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllStates(data));
      store.dispatch(setGettingState(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingState(false));
    });
};

export const getLGA = async (stateId: string) => {
  store.dispatch(setGettingLGA(true));
  const params = {
    stateId,
  };
  await api
    .get("/Utill/GetLGA", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllLGA(data));
      store.dispatch(setGettingLGA(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingLGA(false));
    });
};

export const getReligion = async () => {
  await api
    .get("/Utill/GetReligion")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllReligion(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getAdmissionType = async () => {
  await api
    .get("/Utill/GetAdmissionType")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllAdmissionType(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSubject = async () => {
  await api
    .get("/Utill/GetSubject")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllSubjects(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSubjectGrade = async () => {
  await api
    .get("/Utill/GetSubjectGrade")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAllSubjectGrade(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getExamType = async () => {
  await api
    .get("/Utill/GetExamTypes")
    .then((res) => {
      const { data } = res;
      store.dispatch(setExamTypes(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getExamYear = async () => {
  await api
    .get("/Utill/GetExamYear")
    .then((res) => {
      const { data } = res;
      store.dispatch(setExamYears(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getDepartments = async () => {
  await api
    .get("/Utill/Getdepartments")
    .then((res) => {
      const { data } = res;
      store.dispatch(setDepartments(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getTribes = async () => {
  await api
    .get("/Utill/GetTribes")
    .then((res) => {
      const { data } = res;
      store.dispatch(setTribes(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};
