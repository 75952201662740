/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../Redux/features/ApplicationFormSlice";
import BigButton from "../Components/Form/Buttons/BigButton";
import CustomRegularDropdown from "../Components/Form/Selectors/RegularDropdown";
import { selectUtil } from "../Redux/features/utilSlice";
import Overlay from "../Loaders/Overlay";
import { toast } from "react-toastify";
import { getLGA } from "../Apis/util";
import { advertMeansOptions, disabilityFilters, filter } from "./utils";
import AuthPageLayout from "../Components/Layouts/AuthPageLayout";
import TextInputWithIcon from "../Components/Form/Inputs/TextInputWithIcon";
import moment from "moment";
import { getStudentApplicationInfo } from "../Apis/ApplicationForm";

const PersonalDetails = () => {
  const dispatch = useAppDispatch();
  const {
    title,
    firstName,
    otherName,
    lastName,
    studentEmailId,
    mobileNumber,
    dateOfBirth,
    Gender,
    Religion,
    stateOfOrigin,
    homeTown,
    parentName,
    hobbies,
    parentPhoneNumber,
    confirmingPayment,
    localGovernment,
    department,
    admissionTypeId,
    Disability,
    studentApplicationDetails,
    tribeId,
    advertMeansId,
    referral,
  } = useAppSelector(selectapplicationForm);
  const {
    allState,
    allReligion,
    allLGA,
    departments,
    allAdmissionType,
    tribes,
  } = useAppSelector(selectUtil);
  const { id } = useParams();

  const stateFilter = filter(allState, "stateName", "stateId");
  const religionFilter = filter(allReligion, "religionName", "religionId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const admissionTypeFilter = filter(allAdmissionType, "name", "id");
  const tribeFilter = filter(tribes, "tribeName", "tribeId");
  const localGovernmentFilter = filter(
    allLGA,
    "localGovernmentName",
    "localGovernmentId"
  );
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (
      title &&
      studentEmailId &&
      mobileNumber &&
      dateOfBirth &&
      Religion &&
      Gender &&
      stateOfOrigin &&
      homeTown &&
      parentName &&
      hobbies &&
      parentPhoneNumber &&
      department &&
      tribeId &&
      advertMeansId &&
      Disability
    ) {
      navigate("/next-of-kin-details");
    } else {
      toast.info("Please fill all fields");
    }
  };

  useEffect(() => {
    dispatch(
      getApplicationDetails({
        name: "firstName",
        value: studentApplicationDetails?.firstName,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "lastName",
        value: studentApplicationDetails?.lastName,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "otherName",
        value: studentApplicationDetails?.otherName,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "Country",
        value: "Nigeria",
      })
    );
    dispatch(
      getApplicationDetails({
        name: "studentEmailId",
        value: studentApplicationDetails?.emailAddress,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "mobileNumber",
        value: studentApplicationDetails?.mobilePhone,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "dateOfBirth",
        value: studentApplicationDetails?.dateOfBirth,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "personId",
        value: studentApplicationDetails?.personId,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "contactAddress",
        value: studentApplicationDetails?.contactAddress,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "stateOfOrigin",
        value: studentApplicationDetails?.stateId,
      })
    );
    dispatch(
      getApplicationDetails({
        name: "localGovernment",
        value: studentApplicationDetails?.localGovernmentId,
      })
    );
  }, [dispatch, studentApplicationDetails]);

  useEffect(() => {
    getLGA(stateOfOrigin);
  }, [stateOfOrigin]);

  useEffect(() => {
    getStudentApplicationInfo(id);
  }, []);

  return (
    <AuthPageLayout authText="Personal Details">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Title"
            name="title"
            handleChange={handleOnChange}
            value={title}
          />
          <TextInputWithIcon
            type="text"
            placeholder="First Name"
            name="firstName"
            handleChange={handleOnChange}
            value={firstName}
            readonly={true}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Other Name"
            name="otherName"
            handleChange={handleOnChange}
            value={otherName}
            readonly={true}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Last Name"
            name="lastName"
            handleChange={handleOnChange}
            value={lastName}
            readonly={true}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw>
          <CustomRegularDropdown
            dropdownItems={departmentFilter}
            title="Department"
            name="department"
            handleDropdownSelect={handleSelect}
            value={department}
          />
          <CustomRegularDropdown
            dropdownItems={admissionTypeFilter}
            title="Admission Type"
            name="admissionTypeId"
            handleDropdownSelect={handleSelect}
            value={admissionTypeId}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="email"
            placeholder="Student Email"
            name="studentEmailId"
            handleChange={handleOnChange}
            value={studentEmailId}
          />
          <TextInputWithIcon
            type="tel"
            placeholder="Mobile Number"
            name="mobileNumber"
            handleChange={handleOnChange}
            value={mobileNumber}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Date Of Birth"
            name="dateOfBirth"
            handleChange={handleOnChange}
            value={"Born on " + moment(dateOfBirth).format("MMM d, yyyy")}
            readonly={true}
          />
          <CustomRegularDropdown
            title="Gender"
            name="Gender"
            handleDropdownSelect={handleSelect}
            dropdownItems={[
              { label: "Male", value: 1 },
              { label: "Female", value: 2 },
            ]}
            value={Gender}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Nationality"
            name="nationality"
            handleChange={handleOnChange}
            value={"Nigeria"}
          />
          <CustomRegularDropdown
            title="Religion"
            name="Religion"
            handleDropdownSelect={handleSelect}
            dropdownItems={religionFilter}
            value={Religion}
          />
          <CustomRegularDropdown
            dropdownItems={stateFilter}
            title="State Of Origin"
            name="stateOfOrigin"
            handleDropdownSelect={handleSelect}
            value={stateOfOrigin}
          />
          <CustomRegularDropdown
            dropdownItems={localGovernmentFilter}
            title={"Local Government"}
            name="localGovernment"
            handleDropdownSelect={handleSelect}
            value={localGovernment}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Home Town"
            name="homeTown"
            handleChange={handleOnChange}
            value={homeTown}
          />
          <CustomRegularDropdown
            dropdownItems={tribeFilter}
            title={"Tribe"}
            name="tribeId"
            handleDropdownSelect={handleSelect}
            value={tribeId}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Parent/Sponsor Name"
            name="parentName"
            handleChange={handleOnChange}
            value={parentName}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Parent/Guardian Mobile Number"
            name="parentPhoneNumber"
            handleChange={handleOnChange}
            value={parentPhoneNumber}
          />
          <CustomRegularDropdown
            dropdownItems={advertMeansOptions}
            title={"How did you hear about us?."}
            name="advertMeansId"
            handleDropdownSelect={handleSelect}
            value={advertMeansId}
          />
          {(advertMeansId === 9 ||
            advertMeansId === 10 ||
            advertMeansId === 11) && (
            <TextInputWithIcon
              type="text"
              placeholder="Referer Name"
              name="referral"
              handleChange={handleOnChange}
              value={referral}
            />
          )}
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Hobbies"
            name="hobbies"
            handleChange={handleOnChange}
            value={hobbies}
          />{" "}
          <CustomRegularDropdown
            dropdownItems={disabilityFilters}
            title="Disability"
            name="Disability"
            handleDropdownSelect={handleSelect}
            value={Disability}
          />
        </ItemsCenterJustifyBtw>

        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
      {confirmingPayment && <Overlay />}
    </AuthPageLayout>
  );
};

export default PersonalDetails;
