import { FC } from "react";
import {
  AuthPageContainer,
  AuthText,
  FormSection,
} from "./styles/AuthPageLayout";

interface AuthPageLayoutProps {
  children: any;
  authText: string;
}
const AuthPageLayout: FC<AuthPageLayoutProps> = ({ children, authText }) => {
  return (
    <AuthPageContainer>
      <div className="cover"></div>
      <FormSection>
        <AuthText>{authText}</AuthText>
        {children}
      </FormSection>
    </AuthPageContainer>
  );
};

export default AuthPageLayout;
