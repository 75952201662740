import styled from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../Screens";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center; */
  width: 100vw;
  gap: 1.25rem;
  padding: 20px;

  ${media.tablet} {
    width: 70%;
  }
`;

export const AuthRedirectionLink = styled(Link)`
  color: #fff;
  font-size: var(--fs-400);
  font-weight: var(--fw-bold);
  line-height: normal;
  // margin-top: var(--size-700);
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
`;

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-size: var(--fs-400);
  color: var(--clr-primary-900);
`;

export const ItemsCenterJustifyBtw = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  flex-direction: column;

  .justify-between {
    justify-content: space-between;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .remain-row {
    display: flex;
    flex-direction: row;
  }

  ${media.phone} {
    align-items: center;
    flex-direction: row;
  }
`;

export const GridDisplay = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;

  ${media.phone} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 23px;
  }
  ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const FormCardContainer = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 50px;
  border-radius: 10px;
  border: 1px solid var(--MWB-Sep-Stroke, #f2edff);
  background: rgba(255, 255, 255, 0.45);

  ${media.tablet} {
    padding: 30px;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid var(--MWB-Sep-Stroke, #f2edff);
  background: var(--MWB-White, #fff);
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--clr-black);
`;

export const Subject = styled.span`
  font-size: 20px;
`;
export const GradeContainer = styled.span`
  font-size: 20px;
`;
