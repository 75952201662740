import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface applicationFormState {
  // process states
  sendingApplicationForm: boolean;
  confirmingPayment: boolean;
  studentApplicationDetails: any;

  // Personal Details
  firstName: string;
  otherName: string;
  lastName: string;
  studentEmailId: string;
  mobileNumber: string;
  dateOfBirth: string;
  Gender: number;
  Disability: number;
  Religion: number;
  stateOfOrigin: string;
  homeTown: string;
  parentName: string;
  parentPhoneNumber: string;
  hobbies: string;
  howFoundUs: string;
  emailAddress: string;
  contactAddress: string;
  title: string;
  department: number | null;
  admissionMode: number | null;
  admissionTypeId: number | null;
  personId: number | null;
  tribeId: number | null;
  advertMeansId: number | null;
  referral: string | null;

  // Next of Kin details
  kinFullName: string;
  kinPhoneNumber: string;
  KinEmail: string;
  KinContactAddress: string;
  kinRelationship: number;

  // Address for communication
  country: string;
  state: string;
  localGovernment: string;
  addressLine1: string;
  addressLine2: string;

  // olevel details
  OlevelResult: object[];
  OlevelResult2: object[];
  examType: number;
  examYear: number;
  examNumber: string;
  examType2: number;
  examYear2: number;
  examNumber2: string;
  jambREgNo: string;
  institutionName: string;
  courseOfStudy: string;
  cgpa: string;
  certificateObtained: string;
  classOfCertificate: string;
  dateOfGraduation: string;
  agreement: boolean;
  applicationName: string;
  applicationDate: string;

  // jambResult:
  regNumber: string;
  score: number;
  year: number;
  firstSubjectId: number;
  firstSubjectScore: number;
  secondSubjectId: number;
  seconeSubjectScore: number;
  thirdSubjectId: number;
  thirdSubjectScore: number;
  forthSubjectId: number;
  forthSubjectScore: number;
}

const initialState: applicationFormState = {
  // process state
  sendingApplicationForm: false,
  confirmingPayment: false,
  studentApplicationDetails: null,

  // Personal Details
  firstName: "",
  otherName: "",
  lastName: "",
  studentEmailId: "",
  mobileNumber: "",
  dateOfBirth: "",
  Gender: 0,
  Disability: 0,
  Religion: 0,
  stateOfOrigin: "",
  homeTown: "",
  parentName: "",
  parentPhoneNumber: "",
  hobbies: "",
  howFoundUs: "",
  emailAddress: "",
  title: "",
  contactAddress: "",
  department: null,
  admissionMode: null,
  admissionTypeId: null,
  personId: null,
  tribeId: null,
  advertMeansId: null,
  referral: "",

  // Next of Kin details
  kinFullName: "",
  kinPhoneNumber: "",
  KinEmail: "",
  KinContactAddress: "",
  kinRelationship: 0,

  // Address for communication
  country: "",
  state: "",
  localGovernment: "",
  addressLine1: "",
  addressLine2: "",

  // olevel details
  OlevelResult: [],
  OlevelResult2: [],
  examType: 0,
  examYear: 0,
  examNumber: "",
  examType2: 0,
  examYear2: 0,
  examNumber2: "",
  jambREgNo: "",
  institutionName: "",
  courseOfStudy: "",
  cgpa: "",
  certificateObtained: "",
  classOfCertificate: "",
  agreement: false,
  applicationName: "",
  applicationDate: "",
  dateOfGraduation: "",

  // jamb Result
  regNumber: "",
  score: 0,
  year: 0,
  firstSubjectId: 0,
  firstSubjectScore: 0,
  secondSubjectId: 0,
  seconeSubjectScore: 0,
  thirdSubjectId: 0,
  thirdSubjectScore: 0,
  forthSubjectId: 0,
  forthSubjectScore: 0,
};

export const applicationFormSlice = createSlice({
  name: "applicationForm",
  initialState,
  reducers: {
    setSendingApplicationForm: (state, action) => {
      state.sendingApplicationForm = action.payload;
    },
    setConfirmingPayment: (state, action) => {
      state.confirmingPayment = action.payload;
    },
    setStudentsApplicationDetails: (state, action) => {
      state.studentApplicationDetails = action.payload;
    },
    addToOlevelResult: (state, action) => {
      state.OlevelResult.push(action.payload);
    },
    removeFromOLevelResult: (state, action) => {
      const newResult = state.OlevelResult?.filter(
        (x: any) => x?.subjectId !== action.payload?.subjectId
      );
      state.OlevelResult = newResult;
    },
    addToSecondOlevelResult: (state, action) => {
      state.OlevelResult2.push(action.payload);
    },
    removeFromSecondOLevelResult: (state, action) => {
      const newResult = state.OlevelResult2?.filter(
        (x: any) => x?.subjectId !== action.payload?.subjectId
      );
      state.OlevelResult2 = newResult;
    },
    clearData: (state) => {
      // process state
      state.sendingApplicationForm = false;
      state.confirmingPayment = false;
      state.studentApplicationDetails = null;

      // Personal Details
      state.firstName = "";
      state.otherName = "";
      state.lastName = "";
      state.studentEmailId = "";
      state.mobileNumber = "";
      state.dateOfBirth = "";
      state.Gender = 0;
      state.Disability = 0;
      state.Religion = 0;
      state.stateOfOrigin = "";
      state.homeTown = "";
      state.parentName = "";
      state.parentPhoneNumber = "";
      state.hobbies = "";
      state.howFoundUs = "";
      state.emailAddress = "";
      state.title = "";
      state.contactAddress = "";
      state.department = null;
      state.admissionMode = null;
      state.admissionTypeId = null;
      state.personId = null;
      state.tribeId = null;
      state.advertMeansId = null;
      state.referral = "";

      // Next of Kin details
      state.kinFullName = "";
      state.kinPhoneNumber = "";
      state.KinEmail = "";
      state.KinContactAddress = "";
      state.kinRelationship = 0;

      // Address for communication
      state.country = "";
      state.state = "";
      state.localGovernment = "";
      state.addressLine1 = "";
      state.addressLine2 = "";

      // olevel details
      state.OlevelResult = [];
      state.examType = 0;
      state.examYear = 0;
      state.examNumber = "";
      state.jambREgNo = "";
      state.institutionName = "";
      state.courseOfStudy = "";
      state.cgpa = "";
      state.certificateObtained = "";
      state.classOfCertificate = "";
      state.agreement = false;
      state.applicationName = "";
      state.applicationDate = "";
      state.dateOfGraduation = "";

      // jamb Result
      state.regNumber = "";
      state.score = 0;
      state.year = 0;
      state.firstSubjectId = 0;
      state.firstSubjectScore = 0;
      state.secondSubjectId = 0;
      state.seconeSubjectScore = 0;
      state.thirdSubjectId = 0;
      state.thirdSubjectScore = 0;
      state.forthSubjectId = 0;
      state.forthSubjectScore = 0;
    },

    getApplicationDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
    addToLocalStorage: (state, action) => {
      const savedInfo = JSON.parse(
        localStorage.getItem("studentFilledInfo") || "{}"
      );
      if (savedInfo) {
        // JSON.stringify(
        //   // localStorage.setItem("studentFilledInfo", {...savedInfo, action.payload})
        // );
      } else {
        JSON.stringify(
          localStorage.setItem("studentFilledInfo", action.payload)
        );
      }
    },
  },
});

export const {
  getApplicationDetails,
  setSendingApplicationForm,
  addToOlevelResult,
  removeFromOLevelResult,
  setConfirmingPayment,
  setStudentsApplicationDetails,
  addToLocalStorage,
  clearData,
  addToSecondOlevelResult,
  removeFromSecondOLevelResult,
} = applicationFormSlice.actions;
export const selectapplicationForm = (state: RootState) =>
  state.applicationForm;

export default applicationFormSlice.reducer;
