import React, { FC } from "react";
import styled from "styled-components";

const ParentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 12px;
  color: #70707c;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--clr-grey-100);
  background: var(--clr-white-100);
  transition: all 0.5s ease;

  &:focus-within {
    border-color: var(--clr-black);

    svg {
      color: var(--clr-black);
    }

    .sep {
      background: var(--clr-black);
    }
  }
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  color: var(--clr-grey-200);

  svg {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
`;

const Sep = styled.div`
  width: 1px;
  height: 35px;
  background: linear-gradient(
      0deg,
      var(--clr-grey-200) 0%,
      var(--clr-grey-200) 100%
    ),
    var(--clr-grey-300);
  transition: all 0.5s ease;
`;

const Input = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  background: none;
  color: #000;

  &::placeholder {
    color: #c4c4c4;
  }
`;

interface TextInputProps {
  type: string;
  placeholder?: string;
  value?: any;
  icon?: any;
  name?: string;
  handleChange?: any;
  handleKeyUp?: any;
  max?: string | number;
  min?: string | number;
  maxLength?: number;
  minLength?: number;
  readonly?: boolean;
  onFocus?: any;
}

const TextInputWithIcon: FC<TextInputProps> = ({
  placeholder,
  type,
  icon,
  value,
  name,
  handleChange,
  handleKeyUp,
  max,
  min,
  maxLength,
  minLength,
  readonly,
  onFocus,
}) => {
  return (
    <ParentContainer>
      <Label>{placeholder}:</Label>
      <Container>
        <Icon className="" data-testid="icon">
          {icon}
        </Icon>
        <Sep className="sep" />
        <Input
          placeholder={placeholder}
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          max={max}
          min={min}
          onKeyUp={handleKeyUp}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readonly}
          onFocus={onFocus}
        />
      </Container>
    </ParentContainer>
  );
};

export default TextInputWithIcon;
