import React from "react";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../Redux/features/ApplicationFormSlice";
import AuthPageLayout from "../Components/Layouts/AuthPageLayout";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import TextInputWithIcon from "../Components/Form/Inputs/TextInputWithIcon";
import BigButton from "../Components/Form/Buttons/BigButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddressInfo = () => {
  const dispatch = useAppDispatch();
  const { addressLine1, addressLine2, admissionTypeId } = useAppSelector(
    selectapplicationForm
  );
  const navigate = useNavigate();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (addressLine1) {
      if (admissionTypeId === 1 || admissionTypeId === 3) {
        navigate("/o-level-result-details");
      } else {
        navigate("/other-information");
      }
    } else {
      toast.info("Address Line 1 must be filled");
    }
  };

  return (
    <AuthPageLayout authText="address information">
      <FormContainer>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Country"
            name="country"
            handleChange={handleOnChange}
            value={"Nigeria"}
          />
        </ItemsCenterJustifyBtw>
        <ItemsCenterJustifyBtw className="flex justify-between">
          <TextInputWithIcon
            type="text"
            placeholder="Address Line 1"
            name="addressLine1"
            handleChange={handleOnChange}
            value={addressLine1}
          />
          <TextInputWithIcon
            type="text"
            placeholder="Address Line 2"
            name="addressLine2"
            handleChange={handleOnChange}
            value={addressLine2}
          />
        </ItemsCenterJustifyBtw>
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default AddressInfo;
