import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Header from "../Components/Header";
import { useEffect } from "react";
import {
  getAdmissionType,
  getDepartments,
  getExamType,
  getExamYear,
  getReligion,
  getState,
  getSubject,
  getSubjectGrade,
  getTribes,
} from "../Apis/util";
import PersonalDetails from "../Pages/PersonalDetails";
import ProgramDetails from "../Pages/ProgramDetails";
import NextOfKinDetails from "../Pages/NextOfKinDetails";
import OLevelResult from "../Pages/OLevelResult";
import AddressInfo from "../Pages/AddressInfo";
import OtherInformation from "../Pages/OtherInformation";
import Declaration from "../Pages/Declaration";
import JambResult from "../Pages/JambResult";

const Router = () => {
  useEffect(() => {
    getState();
    getSubject();
    getAdmissionType();
    getSubjectGrade();
    getReligion();
    getExamType();
    getExamYear();
    getDepartments();
    getTribes();
  }, []);
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/program-details" element={<ProgramDetails />} />
          <Route path="/next-of-kin-details" element={<NextOfKinDetails />} />
          <Route path="/O-level-result-details" element={<OLevelResult />} />
          <Route path="/Jamb-result-details" element={<JambResult />} />
          <Route path="/address-info" element={<AddressInfo />} />
          <Route path="/other-information" element={<OtherInformation />} />
          <Route path="/declaration" element={<Declaration />} />
          <Route
            path="/continue-application/:id"
            element={<PersonalDetails />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
