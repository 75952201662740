import React, { useEffect } from "react";
import AuthPageLayout from "../Components/Layouts/AuthPageLayout";
import { FormContainer, ItemsCenterJustifyBtw } from "./styles";
import BigButton from "../Components/Form/Buttons/BigButton";
import { useNavigate } from "react-router-dom";
import CustomRegularDropdown from "../Components/Form/Selectors/RegularDropdown";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  getApplicationDetails,
  selectapplicationForm,
} from "../Redux/features/ApplicationFormSlice";
import { selectUtil } from "../Redux/features/utilSlice";
import { filter } from "./utils";
import TextInputWithIcon from "../Components/Form/Inputs/TextInputWithIcon";
import AddJambResult from "./AddJambResult";
import { toast } from "react-toastify";

const JambResult = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { allExamYears } = useAppSelector(selectUtil);
  const {
    regNumber,
    score,
    year,
    firstSubjectScore,
    seconeSubjectScore,
    thirdSubjectScore,
    forthSubjectScore,
    firstSubjectId,
    secondSubjectId,
    thirdSubjectId,
    forthSubjectId,
  } = useAppSelector(selectapplicationForm);
  const examYearsFilter = filter(allExamYears, "text", "value");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getApplicationDetails({ name, value }));
  };

  const handleSubmit = () => {
   
    if (
      regNumber &&
      score &&
      year &&
      firstSubjectScore &&
      seconeSubjectScore &&
      thirdSubjectScore &&
      forthSubjectScore &&
      firstSubjectId &&
      secondSubjectId &&
      thirdSubjectId &&
      forthSubjectId
    ) {
      navigate("/declaration");
    } else {
      toast.error("please fill all fields");
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getApplicationDetails({ name, value }));
  };

  useEffect(() => {
    const sum =
      Number(firstSubjectScore) +
      Number(seconeSubjectScore) +
      Number(thirdSubjectScore) +
      Number(forthSubjectScore);
    dispatch(getApplicationDetails({ name: "score", value: sum }));
  }, [
    firstSubjectScore,
    seconeSubjectScore,
    thirdSubjectScore,
    forthSubjectScore,
    dispatch,
  ]);

  return (
    <AuthPageLayout authText="Jamb Result">
      <FormContainer>
        <ItemsCenterJustifyBtw>
          <CustomRegularDropdown
            title="Exam Year"
            name="year"
            handleDropdownSelect={handleSelect}
            dropdownItems={examYearsFilter}
            value={year}
          />
          <TextInputWithIcon
            placeholder="Reg Number"
            name="regNumber"
            value={regNumber}
            type="text"
            handleChange={handleOnChange}
          />
          <TextInputWithIcon
            placeholder="Jamb Score"
            name="score"
            value={score}
            type="number"
          />
        </ItemsCenterJustifyBtw>
        <AddJambResult />
        <BigButton
          buttonText="Proceed"
          disabled={false}
          handleClick={handleSubmit}
        />
      </FormContainer>
    </AuthPageLayout>
  );
};

export default JambResult;
