import { styled } from "styled-components";
import { media } from "../../Screens";

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: var(--clr-white-100);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
  gap: 20px;
  position: sticky;
  top: 0;

  ${media.tablet} {
    padding: 30px 20px;
    flex-direction: row;
    height: 65px;
    align-items: center;
    padding: 20px 10px;
  }
`;

interface HeaderBottomProps {
  showMore: boolean;
}

export const HeaderBottom = styled.div<HeaderBottomProps>`
  display: ${(props) => (props.showMore ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 3s ease-in-out;
`;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    width: 50px;
  }

  .top-items {
    ${media.tablet} {
      display: none;
    }
  }
`;

export const HeaderCenter = styled.div``;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  padding-left svg {
    color: var(--clr-grey-200);
    font-size: 15px;
    cursor: pointer;
  }

  ${media.tablet} {
    gap: 20px;

    svg {
      font-size: 25px;
    }
  }

  .avatar-bottom {
    display: none;

    ${media.tablet} {
      display: flex;
    }
  }
`;

export const Avatar = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${media.tablet} {
    width: 45px;
    height: 45px;
  }
`;
